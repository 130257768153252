import axios from 'axios';

const api = axios.create({
    // baseURL:'http://localhost:5001/api'
    baseURL: 'https://hk.yelmedya.com.tr/api'
});

export const getAppointments = async (filterDay,page,page_limit,search,sortField,sortOrder) => {
    return await api.get('/randevu?filterDay='+filterDay+'&page='+page+'&page_limit='+page_limit+'&search='+search + '&sort_field='+ sortField+'&sort_order='+ (sortOrder == 1 ? 'desc' : 'asc'));
};

export const createAppointment = async (appointment) => {
    return await api.post('/randevu', appointment);
};

export const deleteAppointment = async (appointmentId) => {
    return await api.delete(`/randevu/${appointmentId}`);
};

export const updateAppointment = async (appointmentId, appointment) => {
    return await api.put(`/randevu/${appointmentId}`, appointment);
};
